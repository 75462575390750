<template>
  <div class="row">

    <!-- Profile Card -->
    <div class="col-md-12">

      <Card :title="'Form Edit Profile'">
        <template v-slot:body>
          <div class="row align-items-center">

            <!-- Profile Image -->
            <div class="col-md-4">
              <div class="d-flex justify-content-center align-items-center image-container">
                <div style='height: 350px; width: 350px; position: relative'>
                  <img
                    class="image"
                    v-if="form != null"
                    :src="[uploadedImageUrl == '' ? form.photo_name : uploadedImageUrl]"
                    @load="onImageLoad()"
                  >
                </div>
              </div>
            </div>

            <!-- Table Profile Data -->
            <div
              class="col"
              v-if="imgLoaded"
            >
              <b-form
                @submit.stop.prevent="formOnSubmit"
                enctype="multipart/form-data"
              >

                <!-- Input Name -->
                <b-form-group
                  id="input-group-name"
                  label="Nama:"
                  label-for="input-name"
                >
                  <b-form-input
                    id="input-name"
                    v-model="form.name"
                    placeholder="Nama Lengkap"
                  ></b-form-input>
                  <small class="text-danger">{{ error.name }}</small>
                </b-form-group>

                <!-- Input Email -->
                <b-form-group
                  id="input-group-email"
                  label="Email:"
                  label-for="input-email"
                  description=""
                >
                  <b-form-input
                    id="input-email"
                    v-model="form.email"
                    type="email"
                    placeholder="Email"
                  ></b-form-input>
                  <small class="text-danger">{{ error.email }}</small>
                </b-form-group>

                <!-- Profile Image -->
                <b-form-group
                  id="input-group-image"
                  label="Foto Profil:"
                  label-for="input-image"
                >
                  <b-form-file
                    v-model="form.photo"
                    placeholder="Upload Gambar"
                    drop-placeholder="Drop file here..."
                    @change="onFileUpload"
                  ></b-form-file>
                  <small class="text-danger">{{ error.photo }}</small>
                </b-form-group>

                <!-- Submit & Cancel button -->
                <b-button
                  type="submit"
                  variant="primary"
                >Simpan</b-button>
                <b-button
                  class="ml-2"
                  variant="default"
                  @click="$router.push('/profile/show')"
                >
                  Cancel
                </b-button>
              </b-form>
            </div>

          </div>
        </template>
      </Card>

      <Card>
        <template v-slot:body>
          <div class="row justify-content-end">
            <div class="col-md-12">
              <template v-if="completeData.user_type_id == 1">
                <table class="table">
                  <tr>
                    <td><strong>Kartu ID</strong></td>
                    <td>{{ completeData.id_card_number }}</td>
                  </tr>
                  <tr>
                    <td><strong>SIP</strong></td>
                    <td>{{ completeData.sip_number }}</td>
                  </tr>
                  <tr>
                    <td><strong>Masa Berlaku SIP</strong></td>
                    <td>{{ completeData.sip_exp }}</td>
                  </tr>
                  <tr>
                    <td><strong>Poliklinik</strong></td>
                    <td>{{ completeData.polyclinic_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Telepon</strong></td>
                    <td>{{ completeData.phone }}</td>
                  </tr>
                  <tr>
                    <td><strong>Nomor Handphone</strong></td>
                    <td>{{ completeData.mobile_phone }}</td>
                  </tr>
                  <tr>
                    <td><strong>Alamat</strong></td>
                    <td>{{ completeData.address }}</td>
                  </tr>
                </table>
              </template>
              <template v-if="completeData.user_type_id == 3">
                <table class="table">
                  <tr>
                    <td><strong>Kartu ID</strong></td>
                    <td>{{ completeData.id_card_number }}</td>
                  </tr>
                  <tr>
                    <td><strong>Tempat Lahir</strong></td>
                    <td>{{ completeData.birt_place }}</td>
                  </tr>
                  <tr>
                    <td><strong>Tanggal Lahir</strong></td>
                    <td>{{ completeData.birt_date }}</td>
                  </tr>
                  <tr>
                    <td><strong>Departemen</strong></td>
                    <td>{{ completeData.department_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Jabatan</strong></td>
                    <td>{{ completeData.position_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Telepon</strong></td>
                    <td>{{ completeData.phone }}</td>
                  </tr>
                  <tr>
                    <td><strong>Nomor Handphone</strong></td>
                    <td>{{ completeData.mobile_phone }}</td>
                  </tr>
                  <tr>
                    <td><strong>Alamat</strong></td>
                    <td>{{ completeData.address }}</td>
                  </tr>
                </table>
              </template>
            </div>
          </div>
        </template>
      </Card>

    </div>

  </div>
</template>

<script>

import Card from '@/view/content/Card.vue'
import module from '@/core/modules/CrudModule.js'
import validation from '@/core/modules/ValidationModule.js'
import { mapGetters } from 'vuex'
import { getUser, setUser } from '@/core/services/jwt.service.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import { UPDATE_PERSONAL_INFO } from '@/core/services/store/profile.module'

export default {

  components: {
    Card
  },

  data() {
    return {
      // Complete Data
      completeData: {},
      // Form
      form: {
        name: getUser().name,
        email: getUser().email,
        photo: [],
        photo_name: getUser().photo,
        '_method': 'PUT',
      },
      // Error
      error: {
        name: '',
        email: '',
        photo: ''
      },
      // Other
      imgLoaded: false,
      uploadedImageUrl: '',
    }
  },

  methods: {

    onImageLoad() {
      this.imgLoaded = true
    },

    onFileUpload(evt) {
      this.uploadedImageUrl = URL.createObjectURL(evt.target.files[0])
      this.form.photo = evt.target.files[0]
    },

    async formOnSubmit() {
      let formData = new FormData()
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value)
      }
      // Make Request
      let response = await module.submit(formData, 'profile/change-profile/' + getUser().user_id)
      // Check Response
      if (response.state == 'error') {
        // Validation Error
        this.error = validation.setValidationError(this.error, response.error.errors)
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, 'success')
        this.$store.dispatch(UPDATE_PERSONAL_INFO, response.success.user.data)
        setUser(JSON.stringify(response.success.user.data))
        this.$router.push('/profile/show')
      }
    },

    async showCompleteData() {
      this.completeData = await module.get('users/me')
    }

  },

  computed: {
    ...mapGetters(["currentUserPersonalInfo"])
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Profile", route: "/profile/show" },
      { title: "Edit Profile", route: "" },
    ])
    // Mounted
    this.showCompleteData()
  }

}
</script>

<style>
</style>